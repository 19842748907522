/// <summary>
/// File Name : ReportList.js
/// Created By :
/// Purpose : To display the  list of  report names
/// </summary>
/// <returns>  gives the  json data (reports(array-object) and token)</returns>
import { Spinner, Image } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"; // third part package need ti install(npm install react-router-dom@latest)
import { PowerBIService } from "./api/powerbiapi"; // importing the service
import { login_redirect_url } from "./config/indexapi";

const ReportList = () => {
  const username = localStorage.getItem("username");
  const reports_token = localStorage.getItem("id_token");
  // const fidev_url_with_token = `${fidev_url}#id_token=${reports_token}`;
  // console.log(fidev_url_with_token)
  //   sessionStorage.getItem(username);
  // useNavigate used to navigate to different component
  // const navigate = useNavigate();
  const [responseConfig, setResponseConfig] = useState({
    reports: [],
    EmbedToken: "",
  });
  //useState that allows you to add state to a functional component
  // ex:reports is the current state and setReports() is the function that updates the state.
  // const [reports, setReports] = useState([]);
  // const [selectedReport, setSelectedReport] = useState(null);
  const [loading, setLoading] = useState(true);
  //It is used to handle  the  fetching data, updating the DOM
  useEffect(() => {
    async function fetchData() {
      try {
        const url = await PowerBIService.getAllReports();
        const response = url;

        const roles = localStorage.getItem("toolRoles");
        const filtered = response.reports.filter(
          (obj1) => roles.includes(obj1.Id)
          // roles.some((obj2) => obj2.id === obj1.id)
        );
        response.reports = filtered;
        setResponseConfig(response);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false); // Set loading to false when done (whether successful or not)
      }
    }

    fetchData();
  }, []);

  const getImageUrlById = (id) => {
    // Define a mapping of ids to image paths
    const idToImagePath = {
      "e6df1fe7-8ae3-4240-b9f9-2b0ff146a060": "/Group1.png", //cd market data
      "b0cd4acd-6ae6-4951-934d-970a1e955d0a": "/Internet.png", // Market and consumer overview
      "c0f68f89-ef0b-454f-ba32-656fa5a4483f": "/seoColor.png", // points in time chart
      "c45b08f4-5ba0-4b2f-a81b-378de161cd34": "/Group.png", // shopper analytics
      "07532612-1331-46fe-9ba1-d7e723765b6b": "/Digital.png", // Market and consumer Details
      "10f1babb-39d2-4436-88f2-2a22beedf63d":
        "/MarketandConsumerAnalytics-OverviewExternalFI.png", // overview  External fi
      "174afd3f-35dd-4957-a3b8-fb132499143d":
        "/MarketandConsumerAnalytics-DetailsExternalFI.png", //Details External FI
      "7662b25b-f8f3-4564-9b5d-e25238ab2a04": "/Internet.png", //usage metrics
      "d0c07e85-dfcc-4f8d-9ce2-38cbc604483a": "/ReportUsageMetrics.png", //Report usage
      "3ef559ef-937c-4617-b744-792bb2c2bb80": "/PrelimAnalytics.png", // prelims
      "d0fd8684-e5ef-42b7-be7a-a8ae1d8444d7": "/PrelimAnalytics.png",
      "42a8f096-ab3d-4ca0-a54b-4359ece4d5ab": "/APY.png", //APY
      "bef09662-d903-4e18-a392-9ab3e97858b3": "/Exitclicks.png", //Exit clicks
      "a063f48e-49fd-4446-ac27-dbafe9ea7ac4": "/CDMovement.png", //CD Movements
      "aee7e82f-c0da-4924-ba04-2854c2f0bec0": "/YieldCurve.png", //Optimal Yield Curve
      "39445399-868d-40ee-b2cf-970a91ac85b5": "/FILeadForm.png", //FI Lead Form_Internal
      "7deb83db-577e-46ef-bfc4-7a3beee73d3b": "/Internal.png", //Lead Form Report - Seattle Bank
      "804b0cba-36ee-4cc7-a34f-5a887cf9de20": "/Internal.png", //Prism Bank
      "3bb2e0b1-1313-4099-8c16-22761b079d2e": "/Internal.png", //Bank of Utah
      "51cadfd9-c691-4c51-a61e-46ca278eb6c9": "/Internal.png", //Grand Ridge National Bank
      "05bf3c5c-4a39-4008-905e-c6bfcf9d37ca": "/Internal.png", //Keystone Bank SSB
      "e3eb66d0-cc6b-4734-bf3a-d631fe4116a2": "/leadForm.png", //Combined Exit clicks-LeadForm Report
      "67af0edf-122f-480d-a00f-86388ef276ab": "/PrelimAnalytics.png", //PrelimAnalytics-Bank of Iowa
      "c5c61d74-5408-4a2c-a111-7d3dc9e83eb9": "/Internal.png", //FI Lead Form_Internal- Mint National Bank
      "0284f897-236b-4ea4-ba87-a0f5ac136bb7": "/Internal.png", //FI Lead Form_Internal - KindMoney
      "55397b90-4208-4c29-b4e6-9d20c18ac836": "/Internal.png", //	FI Lead Form_Internal- Horicon Bank
      "6d25fe85-0c3d-4fe5-acfc-a31b06322598": "/Exitclicks.png", //Open Now Clicks - Bank of Iowa
      "474cf3a3-cf2c-4e44-9b94-df227c4acd0b": "/Exitclicks.png", //Open Now Clicks - Community Savings
      "a5b6f582-dd0b-4c38-b148-1836032a7e94": "/Exitclicks.png", //Open Now Clicks - Keystone Bank SSB
      "d2ddc0bd-2027-4040-a438-135a4d503f3b": "/Exitclicks.png", //Open Now Clicks - Rising Bank
      "21683156-2cae-46af-9fbe-b2b9eb3bfc4a": "/Exitclicks.png", //FI Open Now to Lead Forms Report
      "d1e2d933-1aef-4853-a410-ad3341e074d1": "/Exitclicks.png", //Open Now Clicks - Stearns Bank N.A
      "2dc3bc1b-5edc-4db9-87f8-b77b1a7ee0c7": "/Exitclicks.png", //Open Now Clicks - Bankwell Bank
      "425a6107-2083-4693-b642-f98d2f6c35f4": "/Internal.png", //FI Lead Form - Grand Ridge National Bank
      "ffc1d9e5-b8ec-4fc6-9174-51c8abce8b46": "/Internal.png", //FI Lead Form - Grand Bank for Savings FSB
      "07acab64-7be3-4afa-a926-c31d5a870cb8": "/Internal.png", //FI Lead Form - Florida Capital Bank N.A.
      "130b8813-e472-4ab4-a45a-a3a352ad06df": "/Internal.png", //FI Lead Form - Clearpath Federal Credit Union
      "2b23dfe5-7a70-4f79-aa4a-16cf5da2a21f": "/marketed-state.png", //Marketed States Report
      "d34de79f-222a-4cb4-b7f5-5d904e869237": "/Internal.png", //FI Lead Form - Members Choice Credit Union
      "d2b44551-a22a-4cb5-9b6c-16e010636646": "/Internal.png", //Fi Lead Form - Flagler Credit Union
      "94961958-c16f-4c37-abe9-c530ac25b93a": "/Internal.png", //FI Lead Form - Jovia Financial Federal C.U.
      "4472fc53-c1d6-4135-80be-071e6b11af3a": "/Internal.png", //FI Lead Form - Dort Financial Credit Union
      "24162e61-428b-4ac5-b686-3fdec3f70fa0": "/Internal.png", //FI Lead Form - Integro Bank
      "fb6e1290-19eb-4502-b0f6-121358b94efb": "/Internal.png", //FI Lead Form - Olympia Federal Savings and Loan Association
      "ec8a2967-9241-4035-a582-883a09e5a1f8": "/Internal.png", //FI Lead Form - Global Innovations Bank
      "a4a4f57c-3e67-454f-b0fc-20e3f12c017e": "/Exitclicks.png", //Open Now Clicks - OMB Bank
      "cfc5ef4f-1249-409d-8783-c8aa3d3b5384": "/Exitclicks.png", //Open Now Clicks - State Bank of India(California)
      "9bf864ce-f7b3-4cf8-91f2-024cac569260": "/InformationDashboard.png", //Account Member-Information Dashboard
      "6ea2572a-155e-4b35-b75e-477d46df8a56": "/Exitclicks.png", //Open Now Clicks - Credit One Bank N.A
      "430f6ca4-c8e8-4b8b-8bcb-6197c3044f86": "/Exitclicks.png", //Open Now Clicks - Bethpage Federal Credit Union
      "451370a5-8377-45ee-8cc4-de2c115c6e0a": "/Exitclicks.png", //Open Now Clicks - Benchmark Federal Credit Union
      "b6da40c0-4642-4feb-bce1-8a1f8dc2f617": "/ONAdashboardIcon.png", //Open Now Click (ONA) Dashboard
      "b1388776-d6be-4b9a-bd54-bd58c957b7f1": "/Exitclicks.png", //Open Now Clicks - Peak Bank
      "a078f502-8290-4101-83d8-7620d5938afa": "/Internal.png", //FI Lead Form - Benchmark Federal Credit Union
      "afcbc250-4b71-40fe-a505-74646a6aac09": "/Exitclicks.png", //Open Now Clicks - Bank of Utah
      "df970158-93eb-45d7-91a8-9bbe058f3ebf": "/MarketandConsumerAnalytics-DetailsExternalFI.png", //Market and Consumer Analytics- Details External FI

      // Add more id-image mappings as needed
    };

    // Use the mapped image path based on the report id
    return idToImagePath[id] || "/default.png"; // Adjust the default image path if needed
  };

  // const handlebuttonclick = () => {
  //   const reports_token = localStorage.getItem("id_token");
  //   const fidev_url_with_token = `${fidev_url}?token=${encodeURIComponent(
  //     reports_token
  //   )}`;
  // console.log(fidev_url_with_token)
  // window.open(fidev_url_with_token, "_self", "noreferrer");
  // window.open(` ${fidev_url}`, '_self', 'noreferrer');
  // };
  // logout function   after logout it redirects to  dashboard
  const handleLogout = () => {
    localStorage.removeItem("username");
    localStorage.removeItem("list_token");
    localStorage.removeItem("id_token");
    // Then navigate to the login page
    // window.open('https://account.cdvalet.com/cdvaletconsumer.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_Sign-in&client_id=0cccdb9a-36f3-4d61-a0ef-ad518b362af3&nonce=defaultNonce&redirect_uri=https://cdvaletembeddedui.azurewebsites.net/list&scope=openid&response_type=id_token&prompt=login', '_self', 'noreferrer');
    // navigate('/');
    window.open(`${login_redirect_url}`, "_self", "noreferrer");
  };
  return (
    <div className="header-view">
      {loading ? ( // Display the loader for the entire header-view when 'loading' is true
        <div className="loader">
          {" "}
          {/* Apply the loader class to the container */}
          <div className="spinner">
            <Spinner animation="border" variant="primary" />
          </div>
        </div>
      ) : (
        <>
          <nav className="navbar navbar-expand-lg bg-light">
            <div className="container-fluid left-right-pad">
              <a className="navbar-brand" href="#">
                <Image className="logo-size" src="/Logo.png" />
                <span className="sub-span">Intelligence Tool</span>
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <div className="nav-end">
                  <ul className="list-inline" style={{ margin: "0px" }}>
                    {/* <li className="list-inline-item" style={{ position: "relative" }}>
                      <i className="fa fa-bell-o bell-icon" aria-hidden="true"></i>
                      <span className="notification">2</span>
                    </li>
                    <li className="list-inline-item">
                      <i className="fa fa-cog bell-icon" aria-hidden="true"></i>
                    </li> */}
                    {/* <li className="list-inline-item  " >
                    <button  className="user-btn" onClick={handlebuttonclick}>User Details</button>
                    </li>  */}

                    <li className="list-inline-item">
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0">
                          <div className="user-log relative">
                            {/* <Image
                              className="user-img cursor"
                              src="/images.jpeg"
                              alt="..."
                            /> */}
                            <Image
                              className="user-img cursor"
                              src="/user-icon.png"
                              alt="..."
                            />
                            {/* <span className="user-letter">{startingLetter}</span> */}
                            <div className="logout-view">
                              <i
                                className="fa fa-caret-up logout-up-icon"
                                aria-hidden="true"
                              ></i>
                              <div className="list-items-sign">
                                <ul className="list-unstyled">
                                  <li className="cursor">
                                    <i
                                      className="fa fa-sign-out"
                                      aria-hidden="true"
                                    ></i>
                                    <a
                                      className="login-href"
                                      onClick={handleLogout}
                                    >
                                      <span>Logout</span>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex-grow-1 ms-2">
                          <p className="userprofile-name">{username}</p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
          <div className="body-main">
            <div className="banner-sec">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-6 col-lg-8 col-sm-6">
                    <div className="banner-text pt-2 ps-2">
                      <h4> Hi {username},</h4>
                      <h3>Welcome Back</h3>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 col-sm-6 text-end center-img">
                    <div className="img-john">
                      <Image className="img-back" src="/banner-img1.png" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bottom-cards">
              <div className="container-fluid left-right-pad">
                <div className="row">
                  <div className="grid">
                    {responseConfig.reports.map((report, index) => (
                      <div className="card" key={report.index}>
                        <div className="header mb-2">
                          <span className="icon">
                            <Image
                              style={{ width: "30px" }}
                              // src="/Digital.png"
                              src={getImageUrlById(report?.Id)}
                            />
                          </span>
                        </div>
                        <span className="cardarrow-right">
                          <i
                            className="fa fa-arrow-right"
                            aria-hidden="true"
                          ></i>
                        </span>
                        <Link
                          to={`/report?reportId=${report?.Id}&embedUrl=${report.EmbedUrl}&token=${responseConfig.token}&reportname=${report.Name}&username=${username}&#id_token=${reports_token}`}
                        >
                          <a className="message">{report?.Name}</a>
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ReportList;
